import {CCol, CContainer, CRow} from '@coreui/react-pro';
import React, {lazy} from 'react';

const PageBackGround = lazy(() => import('../components/helper/PageBackGround'));
const AuthoriseFormNewPasswordCard = lazy(() => import('../components/authorise/form/new-password/Card'));

export default function NewPasswordPage() {
    return (
        <>
            <PageBackGround/>
            <div className="body flex-grow-1 px-2">
                <CContainer className="login__content align-items-center">
                    <CRow className="justify-content-center w-100-row">
                        <CCol className="mw-600">
                            <AuthoriseFormNewPasswordCard/>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
        </>
    );
};
