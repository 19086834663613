import {useQuery} from '@apollo/client';
import {CAlert, CCol, CRow} from '@coreui/react-pro';
import React, {lazy, Suspense, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Loading from '../components/helper/Loading';
import {TENANT_INFORMATION} from '../service/apollo/graphQL/queries/tenant/information';
import {TENANT_PERMISSIONS} from '../service/apollo/graphQL/queries/tenant/permissions';
import {PermissionType} from '../type/permission';
import {TenantType} from '../type/tenant/tenant';

const Breadcrumb = lazy(() => import('../components/helper/Breadcrumb'));
const TenantInformationCard = lazy(() => import('../components/tenant/information/Card'));
const TenantAuthorisedUsersCard = lazy(() => import('../components/tenant/authorised-users/Card'));
const TenantRevenuesCard = lazy(() => import('../components/tenant/revenues/Card'));

export default function TenantPage() {
    const {id} = useParams<{id : string}>();
    const {data: dataTenant} = useQuery<{tenant : TenantType}>(TENANT_INFORMATION, {variables: {id}});
    const {loading, error, data} = useQuery<{tenant : TenantType}>(TENANT_PERMISSIONS, {variables: {id}});
    const [permissions, setPermissions] = useState<PermissionType>({});
    const [name, setName] = useState<string>('');

    useEffect(() => {
        if (data && data.tenant) {
            setPermissions(data.tenant.permissions);
            setName(data.tenant.name);
        }
    }, [data]);

    return (
        <>
            <Breadcrumb items={[
                {name: dataTenant?.tenant.center.name || '', href: '/center/' + dataTenant?.tenant.center.id},
                {name: name, active: true}
            ]}/>

            <CRow xs={{cols: 1}} lg={{cols: 3}}>

                {loading && <CCol><Loading/></CCol>}
                {error && <CCol><CAlert color="danger">{error.message}</CAlert></CCol>}

                {(!loading && !error && id) &&
                    <>
                        <CCol>
                            <Suspense fallback={<></>}>
                                <TenantInformationCard id={id} permissions={permissions}/>
                            </Suspense>
                        </CCol>
                        <CCol>
                            <Suspense fallback={<></>}>
                                <TenantAuthorisedUsersCard id={id} name={name} permissions={permissions}/>
                            </Suspense>
                        </CCol>
                        <CCol>
                            <Suspense fallback={<></>}><TenantRevenuesCard id={id}/></Suspense>
                        </CCol>
                    </>
                }
            </CRow>
        </>
    );
};
