import {useQuery} from '@apollo/client';
import {CAlert, CCol, CRow} from '@coreui/react-pro';
import React, {lazy, Suspense, useEffect, useState} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import Loading from '../components/helper/Loading';
import {CURRENT_USER} from '../service/apollo/graphQL/queries/user/currentUser';
import {UsersType} from '../type/users';

const Breadcrumb = lazy(() => import('../components/helper/Breadcrumb'));
const UserCard = lazy(() => import('../components/user/Card'));
const UserPasswordCard = lazy(() => import('../components/user/PasswordCard'));
const UserActionCard = lazy(() => import('../components/user/ActionCard'));

type MyAccountPageProps = WithTranslation & {
    setToken : (token : string) => void
};

function MyAccountPage({t, setToken} : MyAccountPageProps) {
    const {loading, error, data} = useQuery(CURRENT_USER);
    const [user, setUser] = useState<UsersType | null>(null);

    useEffect(() => {
        if (data) {
            setUser(data.me);
        }
    }, [data]);

    if (loading) {
        return <CRow><CCol><Loading/></CCol></CRow>;
    }

    if (error) {
        return <CRow><CCol><CAlert color="danger">{error.message}</CAlert></CCol></CRow>;
    }

    return (
        <>
            <Breadcrumb items={[
                {name: t('user:page.breadcrumb.my-account', '~Mein Benutzerkonto'), active: true}
            ]}/>

            <CRow>
                <CCol md={8} lg={6} xl={4} className="col-user offset-md-2 offset-lg-0">
                    {user &&
                        <Suspense fallback={<></>}>
                            <UserCard user={user}/>
                            <CRow>
                                <CCol className="col__user-actions">
                                    <Suspense fallback={<></>}>
                                        <UserActionCard user={user} isCurrentUser={true}/>
                                    </Suspense>
                                </CCol>
                            </CRow>
                        </Suspense>
                    }
                </CCol>
                <CCol md={8} lg={6} xl={4} className="col-user-password offset-md-2 offset-lg-0">
                    {user &&
                        <Suspense fallback={<></>}>
                            <UserPasswordCard user={user} setToken={setToken}/>
                        </Suspense>
                    }
                </CCol>
            </CRow>
        </>
    );
}

export default withTranslation(['user'])(MyAccountPage);
