import {gql} from '@apollo/client';

export const USER_SHORT_FRAGMENT = gql `
    fragment userShort on User {
        id
        gender
        firstName
        lastName
        email
        phone
        language
        toolAdmin
        lastLogin
        stopReminderMail
    }
`;
