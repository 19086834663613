import {ApolloProvider} from '@apollo/client';
import React, {useEffect, useState} from 'react';
import useLocalStorage from 'react-use-localstorage';
import {TokenContext} from './context/tokenContext';
import PageRoutes from './pages/PageRoutes';

import './scss/coreui.scss';
import {getApolloClient} from './service/apollo/client';

export default function CenterIndexApp() {
    const [token, setToken] = useLocalStorage('center-index-token', '');
    const [contextValues, setContextValues] = useState({token: ''});

    useEffect(() => {
        setContextValues({token: token});
    }, [token]);

    return (
        <TokenContext.Provider value={contextValues}>
            <ApolloProvider client={getApolloClient(token)}>
                <PageRoutes setToken={setToken}/>
            </ApolloProvider>
        </TokenContext.Provider>
    );
}
