import {CAlert, CCol, CContainer, CRow, CSpinner} from '@coreui/react-pro';
import React from 'react';
import {Trans} from 'react-i18next';

export default function SuspenseLoader() {
    return (
        <div className="body flex-grow-1 px-2">
            <CContainer className="login__content align-items-center">
                <CRow className="justify-content-center w-100">
                    <CCol xs="auto">
                        <CAlert color="secondary">
                            <CSpinner color="primary" className="d-flex ma mb-2"/>
                            <Trans i18nKey="common:helper.suspense_loader">
                                Daten werden geladen...
                            </Trans>
                        </CAlert>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}
