import {gql} from '@apollo/client';
import {CENTER_PERMISSIONS_FRAGMENT} from '../../fragments/center/permissions';

export const CENTER_PERMISSIONS = gql `
    ${CENTER_PERMISSIONS_FRAGMENT}
    query GetCenterPermissions($id: String!) {
        center(id: $id) {
            id
            name
            ...CenterPermissions
        }
    }
`;
