import {gql} from '@apollo/client';
import {TENANT_PERMISSIONS_FRAGMENT} from '../../fragments/tenant/permissions';

export const TENANT_PERMISSIONS = gql `
    ${TENANT_PERMISSIONS_FRAGMENT}
    query GetTenantPermissions($id: String!) {
        tenant(id: $id) {
            id
            name
            ...TenantPermissions
        }
    }
`;
