import {CCol, CRow} from '@coreui/react-pro';
import React, {lazy, Suspense} from 'react';
import {useParams} from 'react-router-dom';

const CenterReportsCard = lazy(() => import('../components/center/reports/Card'));
const CenterReportsSidebar = lazy(() => import('../components/center/reports/Sidebar'));

export default function CenterReportsPage() {
    const {id} = useParams<{ id : string }>();

    return (
        <CRow>
            <CCol className="col-report">
                {id &&
                    <Suspense fallback={<></>}>
                        <CenterReportsCard centerId={id}/>
                    </Suspense>
                }
            </CCol>

            {id &&
                <Suspense fallback={<></>}>
                    <CenterReportsSidebar centerId={id}/>
                </Suspense>
            }
        </CRow>
    );
};
