import {gql} from '@apollo/client';
import {USER_SHORT_FRAGMENT} from '../../fragments/user/short';

export const CURRENT_USER = gql `
    ${USER_SHORT_FRAGMENT}
    query GetCurrentUser {
        me {
            ...userShort
            permissions {
                canDelete
                canUpdate
            }
        }
    }
`;
