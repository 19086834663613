import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';
import configData from '../config/config.json';
import {setDatePickerDefaultLocale} from './datePicker';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // lazy loading for translations
    .use(backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        ns: 'common',
        fallbackLng: 'de',

        // prevent 404 resources not found
        supportedLngs: configData.SUPPORTED_LANGUAGES
        // Do not set to prevent 404 resources not found
        // nonExplicitSupportedLngs: true,
    });

export const changeLanguage = (language : string) => {
    i18n.changeLanguage(language).then(() => {
        setDatePickerDefaultLocale(language);
    });
};

export const setHtmlLanguage = (language : string) => {
    let locale = 'de_CH';

    switch (language) {
        case 'de':
            locale = 'de_CH';
            break;
        case 'fr':
            locale = 'fr_FR';
            break;
        case 'it':
            locale = 'it_IT';
            break;
    }

    document.documentElement.lang = locale;
};

export default i18n;
