import React from 'react';
import {Route, Routes} from 'react-router-dom';
import CenterPage from './CenterPage';
import CenterReportsPage from './CenterReportsPage';
import DashboardPage from './DashboardPage';
import GlobalReportsPage from './GlobalReportsPage';
import InvitationPage from './InvitationPage';
import LoginPage from './LoginPage';
import NewPasswordPage from './NewPasswordPage';
import NotFoundPage from './NotFoundPage';
import PageLayout from './PageLayout';
import RealEstateManagementsPage from './RealEstateManagementsPage';
import SectorsPage from './SectorsPage';
import TenantPage from './TenantPage';
import MyAccountPage from './MyAccountPage';
import UserPage from './UserPage';
import UsersPage from './UsersPage';

type PageRoutesProps = {
    setToken : (token : string) => void
};

export default function PageRoutes({setToken} : PageRoutesProps) {
    return (
        <Routes>
            <Route path="invitation" element={<InvitationPage/>}/>
            <Route path="login" element={<LoginPage setToken={setToken}/>}/>
            <Route path="forgot-password" element={<LoginPage setToken={setToken}/>}/>
            <Route path="newpassword" element={<NewPasswordPage/>}/>

            <Route path="/" element={<PageLayout setToken={setToken}/>}>
                <Route index element={<DashboardPage/>}/>
                <Route path="center/:id" element={<CenterPage/>}/>
                <Route path="center/:id/reports" element={<CenterReportsPage/>}/>
                <Route path="tenant/:id" element={<TenantPage/>}/>
                <Route path="my-account" element={<MyAccountPage setToken={setToken}/>}/>
                <Route path="user/:id" element={<UserPage/>}/>
                <Route path="users" element={<UsersPage/>}/>
                <Route path="real-estate-managements" element={<RealEstateManagementsPage/>}/>
                <Route path="sectors" element={<SectorsPage/>}/>
                <Route path="*" element={<NotFoundPage/>}/>
                <Route path="reports" element={<GlobalReportsPage/>}/>
            </Route>
        </Routes>
    );
};
