import {ApolloClient, from, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {RetryLink} from '@apollo/client/link/retry';
import {createUploadLink} from 'apollo-upload-client';

export function getApolloClient(token : string) {
    const beUrl = process.env.REACT_APP_BE_URL || '';
    const apiPath = process.env.REACT_APP_API_PATH || '';

    const uploadLink = createUploadLink({
        uri: beUrl + apiPath
    });

    const authLink = setContext((_, {headers}) => {
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            }
        }
    });

    // https://www.apollographql.com/docs/react/v3.0-beta/api/link/apollo-link-retry/
    const retryLink = new RetryLink({
        delay: {
            initial: 300,
            max: Infinity,
            jitter: true,
        },
        attempts: {
            max: 3,
            retryIf: (error, _operation) => {
                // 401 = "Authorization required"
                return !!error && error.statusCode !== 401;
            },
        },
    })

    // TODO kp: server errors 500 or 404, etc --> show a message, like 'service temporarily unavailable'
    const errorLink = onError(({
        graphQLErrors,
        networkError,
        operation,
        forward
    }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({message, locations, path}) =>
                console.error(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                ),
            );

            for (let err of graphQLErrors) {
                switch (err.extensions.code) {
                    // Apollo Server sets code to UNAUTHENTICATED
                    // when an AuthenticationError is thrown in a resolver
                    case 'UNAUTHENTICATED':
                        // Modify the operation context with a new token
                        const oldHeaders = operation.getContext().headers;

                        operation.setContext({
                            headers: {
                                ...oldHeaders,
                                authorization: token ? `Bearer ${token}` : '',
                            }
                        });
                        // Retry the request, returning the new observable
                        return forward(operation);
                }
            }
        }

        if (networkError) {
            // console.log('[Network error (src/apollo/client.ts)]', networkError);
            // console.log('[Operation mame (src/apollo/client.ts)]', operation.operationName);
        }
    });

    return new ApolloClient({
        link: authLink.concat(from([errorLink, retryLink, uploadLink])),
        cache: new InMemoryCache({
            resultCaching: true
        }),
        // connectToDevTools: true
    });

}
