import {CCardGroup, CCol, CContainer, CRow} from '@coreui/react-pro';
import React, {lazy, useEffect, useLayoutEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import './LoginPage.scss';
import {getLandingPageBoxContent} from '../service/landing-page-box';

const PageBackGround = lazy(() => import('../components/helper/PageBackGround'));
const AuthoriseFormForgotPasswordCard = lazy(() => import('../components/authorise/form/forgot-password/Card'));
const AuthoriseFormLoginCard = lazy(() => import('../components/authorise/form/login/Card'));
const LandingPageBox = lazy(() => import('../components/helper/LandingPageBox'));

type LoginPageProps = {
    setToken : (token : string) => void
}

export default function LoginPage({setToken} : LoginPageProps) {
    const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);
    const location = useLocation();
    const [boxContent, setBoxContent] = useState<string>('');

    useEffect(() => {
        if (boxContent === '') {
            getLandingPageBoxContent().then(response => {
                if (response !== 'error') {
                    setBoxContent(response);
                }
            });
        }
    }, [boxContent]);

    useLayoutEffect(() => {
        if (location.pathname !== '' && location.pathname.includes('forgot-password')) {
            setIsForgotPassword(true);
        }
    }, [location]);

    return (
        <>
            <PageBackGround/>
            <div className="body flex-grow-1 px-2">
                <CContainer className="login__content align-items-center">
                    <CRow className="justify-content-center w-100-row">
                        {!isForgotPassword ?
                            (
                                <CCol md={12} lg={10} xl={8}>
                                    <CCardGroup>
                                        <AuthoriseFormLoginCard setToken={setToken}
                                                                setIsForgotPassword={setIsForgotPassword}/>
                                        <LandingPageBox boxContent={boxContent}/>
                                    </CCardGroup>
                                </CCol>
                            ) : (
                                <CCol md={6} lg={5} xl={4}>
                                    <AuthoriseFormForgotPasswordCard setIsForgotPassword={setIsForgotPassword}/>
                                </CCol>
                            )
                        }
                    </CRow>
                </CContainer>
            </div>
        </>
    );
};
