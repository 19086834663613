import {gql} from '@apollo/client';

export const TENANT_INFORMATION_FRAGMENT = gql`
    fragment tenantInformation on Tenant {
        id
        name
        reference
        sector {
            id
            name
        }
        joinDate
        leaveDate
        salesArea
        center {
            id
            name
        }
    }
`;
