import {CCol, CRow} from '@coreui/react-pro';
import React, {lazy} from 'react';

const NotFoundContent = lazy(() => import('../components/notFound/Content'));

export default function NotFoundPage() {
    return (
        <CRow>
            <CCol>
                <NotFoundContent/>
            </CCol>
        </CRow>
    );
};
