import {CCol, CContainer, CRow} from '@coreui/react-pro';
import React, {lazy} from 'react';

const InvitationCard = lazy(() => import('../components/invitation/Card'));
const PageBackGround = lazy(() => import('../components/helper/PageBackGround'));

export default function InvitationPage() {
    return (
        <>
            <PageBackGround/>
            <div className="body flex-grow-1 px-sm-2">
                <CContainer className="login__content align-items-center">
                    <CRow className="justify-content-center w-100-row">
                        <CCol className="mw-600">
                            <InvitationCard/>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
        </>
    );
};
