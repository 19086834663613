import {useQuery} from '@apollo/client';
import {CAlert, CCol, CRow} from '@coreui/react-pro';
import React, {lazy, Suspense, useEffect, useState} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import Loading from '../components/helper/Loading';
import {CURRENT_USER} from '../service/apollo/graphQL/queries/user/currentUser';
import {USER_FOR_EDIT_BY_ADMIN} from '../service/apollo/graphQL/queries/user/userForEditByAdmin';
import {CurrentUserType} from '../type/user/currentUser';
import {UsersType} from '../type/users';

const Breadcrumb = lazy(() => import('../components/helper/Breadcrumb'));
const UserCard = lazy(() => import('../components/user/Card'));
const UserActionCard = lazy(() => import('../components/user/ActionCard'));
const UserToolAdminCard = lazy(() => import('../components/user/ToolAdminCard'));
const UserCenterAdminCard = lazy(() => import('../components/user/CenterAdminCard'));
const UserCenterDirectorCard = lazy(() => import('../components/user/CenterDirectorCard'));
const UserTenantUserCard = lazy(() => import('../components/user/TenantUserCard'));

function UserPage({t} : WithTranslation) {
    const {id} = useParams<{id : string}>();
    const {loading: loadingMe, error: errorMe, data: dataMe} = useQuery(CURRENT_USER);
    const {loading, error, data} = useQuery(USER_FOR_EDIT_BY_ADMIN, {variables: {id}});
    const [userMe, setUserMe] = useState<CurrentUserType | null>(null);
    const [user, setUser] = useState<UsersType | null>(null);

    useEffect(() => {
        if (dataMe) {
            setUserMe(dataMe.me);
        }
    }, [dataMe]);

    useEffect(() => {
        if (data) {
            setUser(data.user);
        }
    }, [data]);

    if (loading || loadingMe) {
        return <CRow><CCol><Loading/></CCol></CRow>;
    }

    if (error || errorMe) {
        return <CRow><CCol>
            <CAlert color="danger">{error ? error.message : (errorMe ? errorMe.message : 'unknown error')}</CAlert>
        </CCol></CRow>;
    }

    if (!user || !userMe || !user.permissions.canUpdate) {
        return <CRow><CCol>
            <CAlert color="danger">{t('user:page.error.no-user', '~Der Benutzer konnte nicht geladen werden')}</CAlert>
        </CCol></CRow>;
    }

    return (
        <>
            <Breadcrumb items={[
                {name: t('users:page.breadcrumb.users', '~Übersicht Benutzer'), href: '/users'},
                {name: t('user:page.breadcrumb.user', '~Benutzerkonto verwalten'), active: true}
            ]}/>
            <CRow>
                <CCol md={8} lg={6} xl={4} className="col-user offset-md-2 offset-lg-0">
                    <Suspense fallback={<></>}>
                        <UserCard user={user}/>
                        <CRow>
                            <CCol className="col__user-actions">
                                <Suspense fallback={<></>}>
                                    <UserActionCard user={user} isCurrentUser={false}/>
                                </Suspense>
                            </CCol>
                        </CRow>
                    </Suspense>
                </CCol>
                <CCol md={8} lg={6} xl={4} className="col__user-permissions offset-md-2 offset-lg-0">
                    <CRow>
                        <CCol className="col__user-permissions--tool-admin">
                            <Suspense fallback={<></>}>
                                <UserToolAdminCard user={user} userMe={userMe}/>
                            </Suspense>
                        </CCol>
                    </CRow>
                    {(user.centerAdminsForRealEstateManagements && user.centerAdminsForRealEstateManagements.length > 0) &&
                        <CRow>
                            <CCol className="col__user-permissions--center-admin">
                                <Suspense fallback={<></>}>
                                    <UserCenterAdminCard user={user}/>
                                </Suspense>
                            </CCol>
                        </CRow>
                    }
                    {(user.centerDirectorsForCenters && user.centerDirectorsForCenters.length > 0) &&
                        <CRow>
                            <CCol className="col__user-permissions--center-director">
                                <Suspense fallback={<></>}>
                                    <UserCenterDirectorCard user={user}/>
                                </Suspense>
                            </CCol>
                        </CRow>
                    }
                    {(user.tenantManagerForActiveTenants && user.tenantManagerForActiveTenants.length > 0) &&
                        <CRow>
                            <CCol className="col__user-permissions--tenant-user">
                                <Suspense fallback={<></>}>
                                    <UserTenantUserCard user={user}/>
                                </Suspense>
                            </CCol>
                        </CRow>
                    }
                </CCol>
            </CRow>
        </>
    );
}

export default withTranslation(['user', 'users'])(UserPage);
