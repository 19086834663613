import {CCol, CRow} from '@coreui/react-pro';
import React, {lazy, Suspense} from 'react';

const GlobalReportsCard = lazy(() => import('../components/globalReports/Card'))

export default function GlobalReportsPage() {

    return (
        <CRow>
            <CCol className="col-globalReport">
                <Suspense fallback={<></>}>
                    <GlobalReportsCard />
                </Suspense>
            </CCol>
        </CRow>
    );
};
