import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {I18nextProvider} from 'react-i18next';
import {BrowserRouter} from 'react-router-dom';
import CenterIndexApp from './CenterIndexApp';
import SuspenseLoader from './components/helper/SuspenseLoader';

import i18next, {setHtmlLanguage} from './utilities/i18n';

i18next.on('languageChanged', (language) => {
    setHtmlLanguage(language);
});

// TODO kp: remove after regression bug is fixed
// Uncaught ReferenceError: process is not defined
window.process = {} as any;

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <I18nextProvider i18n={i18next}>
                <Suspense fallback={<SuspenseLoader/>}>
                    <CenterIndexApp/>
                </Suspense>
            </I18nextProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.querySelector('[data-app-id="center-index-root"]')
);
