import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import {registerLocale, setDefaultLocale} from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('it', it);

export const setDatePickerDefaultLocale = (language : string) : void => {
    setDefaultLocale(language);
};
