import {CCol, CRow} from '@coreui/react-pro';
import React, {lazy, Suspense} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';

const Breadcrumb = lazy(() => import('../components/helper/Breadcrumb'));
const SectorsCard = lazy(() => import('../components/sectors/overview/Card'));

function SectorsPage({t} : WithTranslation) {
    return (
        <>
            <Breadcrumb items={[
                {
                    name: t('sectors:page.breadcrumb.sectors', '~Übersicht Branchen'),
                    active: true
                }
            ]}/>
            <CRow>
                <CCol xl={8} xxl={7}>
                    <Suspense fallback={<></>}><SectorsCard/></Suspense>
                </CCol>
            </CRow>
        </>
    );
}

export default withTranslation(['sectors'])(SectorsPage);
