import {CCol, CRow} from '@coreui/react-pro';
import React, {lazy, Suspense} from 'react';

const DashboardCard = lazy(() => import('../components/dashboard/Card'));

export default function DashboardPage() {
    return (
        <CRow>
            <CCol>
                <Suspense fallback={<></>}>
                    <DashboardCard/>
                </Suspense>
            </CCol>
        </CRow>
    );
};
