import {gql} from '@apollo/client';

export const TENANT_PERMISSIONS_FRAGMENT = gql `
    fragment TenantPermissions on Tenant {
        permissions {
            canDelete
            canManageTenantManagers
            canUpdate
            canUpdateSalesArea
            canUpdateSector
            canUpdateValidity
            canUpdateReference
        }
    }
`;
