import axios from 'axios';
import i18n from 'i18next';

export const getLandingPageBoxContent = async () : Promise<string> => {
    const lang = (i18n.language) + '/';
    const boxContent = await getLandingPageBoxContentByLang(lang);

    return boxContent !== 'error' ? boxContent : await getLandingPageBoxContentByLang();
};

export const getLandingPageBoxContentByLang = async (lang : string = 'de/') : Promise<string> => {
    const beUrl = process.env.REACT_APP_BE_URL || '';
    const boxContentPath = process.env.REACT_APP_API_LOGIN_BOX_CONTENT_PATH || '';

    return await axios.get<string>(beUrl + lang + boxContentPath)
                      .then((response) => {
                          return response.data;
                      }).catch((error : any) => {
            console.error('getLandingPageBoxContent error: ', error);

            return 'error'
            // console.log('error.config', error.config);
        });
};
