import {CSpinner} from '@coreui/react-pro';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';

type LoadingProps = WithTranslation & {
    className? : string
    color? : string
};

function Loading({t, className, color} : LoadingProps) {
    return <CSpinner
        className={className}
        color={color || 'light'}
        visuallyHiddenLabel={t('common:helper.loading', '~Daten werden geladen...')}
        size="sm"
        component="span"
    />;
}

export default withTranslation(['common'])(Loading);
