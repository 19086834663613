import {gql} from '@apollo/client';

export const CENTER_PERMISSIONS_FRAGMENT = gql `
    fragment CenterPermissions on Center {
        permissions {
            canSeeContactGroup
            canSeeKeyFiguresGroup
            canUpdate
            canUpdateRealEstateManagement
            canUpdateFrequency
            canManageNetSalesAreas
            canManageCenterDirectors
            canCreateTenant
            canDelete
            canArchive
            canSeeReports
            canAccessCenterClosing
            canEditBusinessReports
        }
    }
`;
