import {gql} from '@apollo/client';
import {TENANT_INFORMATION_FRAGMENT} from '../../fragments/tenant/information';

export const TENANT_INFORMATION = gql `
    ${TENANT_INFORMATION_FRAGMENT}
    query GetTenantInformation($id: String!) {
        tenant(id: $id) {
            id
            ...tenantInformation
        }
    }
`
