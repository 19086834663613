import {gql} from '@apollo/client';
import {USER_FRAGMENT} from '../../fragments/user/user';

export const USER_FOR_EDIT_BY_ADMIN = gql `
    ${USER_FRAGMENT}
    query GetUser($id: String!) {
        user(id: $id) {
            ...user
        }
    }
`;
