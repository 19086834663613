import {CCol, CRow} from '@coreui/react-pro';
import React, {lazy, Suspense} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';

const Breadcrumb = lazy(() => import('../components/helper/Breadcrumb'));
const UsersCard = lazy(() => import('../components/users/Card'));

function UsersPage({t} : WithTranslation) {
    return (
        <>
            <Breadcrumb items={[
                {name: t('users:page.breadcrumb.users', '~Übersicht Benutzer'), active: true}
            ]}/>
            <CRow>
                <CCol className="col-user-list">
                    <Suspense fallback={<></>}>
                        <UsersCard/>
                    </Suspense>
                </CCol>
            </CRow>
        </>
    );
}

export default withTranslation(['users'])(UsersPage);
