import {CContainer} from '@coreui/react-pro';
import React, {lazy, useContext} from 'react';
import {Outlet} from 'react-router-dom'
import {TokenContext} from '../context/tokenContext';

const LoginPage = lazy(() => import('./LoginPage'));
const Header = lazy(() => import('../components/header/Header'));
const ContactInformation = lazy(() => import('../components/contact-information/ContactInformation'));
const Footer = lazy(() => import('../components/footer/Footer'));

type PageLayoutProps = {
    setToken : (token : string) => void
}

export default function PageLayout({setToken} : PageLayoutProps) {
    const {token} = useContext(TokenContext);

    if (token === '') {
        return <LoginPage setToken={setToken}/>;
    }

    return (
        <>
            <Header token={token} setToken={setToken}/>
            <main className="body flex-grow-1 px-md-2">
                <CContainer fluid>
                    <Outlet/>
                </CContainer>
            </main>

            <ContactInformation/>
            <Footer/>
        </>
    );
};
