import {gql} from '@apollo/client';
import {USER_SHORT_FRAGMENT} from './short';

export const USER_FRAGMENT = gql `
    ${USER_SHORT_FRAGMENT}
    fragment user on User {
        ...userShort
        permissions  {
            canUpdate
            canDelete
        }
        centerAdminsForRealEstateManagements {
            id
            name
            centers {
                id
            }
        }
        centerDirectorsForCenters {
            id
            name
        }
        tenantManagerForActiveTenants {
            id
            name
            center {
                id
                name
            }
        }
        invitationPending
        invitation {
            expirationDate
            expired
            invitationDate
            inviterEmail
        }
    }
`;
